import React from 'react';
import './FullHeightBlock.scss';

const FullHeightBlock = (props) => {
	return (
		<section className="full_heigh_block">
			{props.children}
		</section>
	);
};

export default FullHeightBlock;
