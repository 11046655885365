import React, {PureComponent, Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import CenterBlock from "../components/Elements/CenterBlock/CenterBlock";
import PortfolioFilter from "../components/PortfolioFilter";
import {inject, observer} from "mobx-react";
import Loading from "../components/Elements/Loading";
import PortfolioItem from "../components/PortfolioFilter/PortfolioItem";

@inject('Store', 'Actions')
@observer
class Protfolio extends PureComponent {

	state = {
		value: 'all',
	};

	componentDidMount() {
		const {Store, Actions} = this.props;
		Store.portfolio.status = false;
		Actions.getPortfolio();
	}

	componentWillUnmount() {
		const {Store} = this.props;
		Store.portfolio = {
			status: false,
			data: {}
		};
	}

	onHandleChange = (e) => {
		const {Store} = this.props;
		Store.portfolio.status = false;
		this.setState({value: e});
		setTimeout(() => {
			Store.portfolio.status = true;
		}, 200)
	};

	render() {

		const {Store} = this.props;

		return (
			<Fragment>
				<Header main={false}/>
				<CenterBlock>
					<div className="content_page_block">
						<h1>Портфолио</h1>
						<PortfolioFilter
							value={this.state.value}
							onCallBack={this.onHandleChange}
						/>
						<div className="portfolio_filter_content">
							{
								Store.portfolio.status ? (
									Store.portfolio.data.acf.portfolio.map((item, index) => {
										return (
											<PortfolioItem
												key={index}
												pic={item.pic}
												razdel={item.razdel}
												slug={this.state.value}
											/>
										);
									})
								) : <Loading fullHeight={true}/>
							}
						</div>
					</div>
				</CenterBlock>
				<Footer/>
			</Fragment>
		);
	}
}

export default Protfolio;
