import React, {PureComponent, Fragment} from 'react';
import './UploadFile.scss';
import iconFile from './icon.svg';
import fileItem from './file-icon.svg';
import deleteIcon from './delete-icon.svg';

class UploadFile extends PureComponent {

	state = {
		error: false,
		file: []
	};

	onHandleChange = (e) => {
		this.setState({error: false});
		let array = [];
		Array.from(e.target.files).forEach(file => {
			const fileExtension = file.name.split('.');
			const fileExtensionLenght = fileExtension.length;
			const fileItem = fileExtension[fileExtensionLenght - 1];
			if (
				fileItem === 'png' ||
				fileItem === 'pdf' ||
				fileItem === 'jpg' ||
				fileItem === 'txt' ||
				fileItem === 'doc' ||
				fileItem === 'xls' ||
				fileItem === 'docx'
			) {
				array.push(file);
			} else {
				this.setState({error: true})
			}
		});
		this.setState({file: array});
	};

	onHandleDelete = (name) => {
		const array = this.state.file.filter(item => item.name !== name);
		this.setState({...this.state.file, file: array, error: false});
	};

	render() {
		return (
			<Fragment>
				<div className="smart_upload">
					<label>
						<input
							onChange={this.onHandleChange}
							multiple={true}
							type="file"
						/>
						<img src={iconFile} alt="Прикрепить свои файлы"/>
						<span>Прикрепить свои файлы</span>
					</label>
				</div>
				{
					this.state.file.length > 0 ? (
						<div className="smart_upload_files">
							<ul>
								{
									this.state.file.map((item, index) => {
										return (
											<li key={index}>
												<img src={fileItem} alt="mini-file-upd.jpg"/>{item.name}
												<button onClick={() => this.onHandleDelete(item.name)}><img src={deleteIcon}
												                                                            alt="Удалить файл"/></button>
											</li>
										)
									})
								}
							</ul>
						</div>
					) : null
				}
				{
					this.state.error ? (
						<div className="error_file">Ошибка файл не поддерживается</div>
					) : null
				}
			</Fragment>
		);
	}
}

export default UploadFile;
