import React, {Fragment, PureComponent} from 'react';
import Input from "../../Forms/Input";
import PhoneInput from "../../Forms/PhoneInput";
import TextArea from "../../Forms/TextArea";
import CustomCheckBox from "../../Forms/CustomCheckBox";
import ErrorMessage from "../../Forms/ErrorMessage";
import Button from "../../Forms/Button";
import {inject, observer} from "mobx-react";
import UploadFile from "../../Forms/UploadFile";

@inject('Store')
@observer
class Calculate extends PureComponent {

	state = {
		checked: false,
		checkedError: false,
		name: '',
		email: '',
		phone: '',
		comment: '',
	};

	onHandleChange = (e) => {
		this.setState({...this.state, ...e}, () => console.log(this.state))
	};

	onHandleSubmit = () => {
		const {Store} = this.props;
		if (this.state.checked) {
			this.setState({checkedError: false});
			Store.order_calculation_modal = false;
		} else {
			this.setState({checkedError: true})
		}
	};

	render() {
		return (
			<Fragment>
				<Input
					type="text"
					name="name"
					value={this.state.name}
					placeholder="Ваше имя*"
					mbottom={10}
					onCallBack={(e) => this.onHandleChange(e)}
				/>
				<Input
					type="email"
					name="email"
					placeholder="Ваш Email*"
					value={this.state.email}
					mbottom={10}
					onCallBack={(e) => this.onHandleChange(e)}
				/>
				<PhoneInput
					type="text"
					name="phone"
					placeholder="Укажите номер телефона*"
					onCallBack={e => this.onHandleChange(e)}
					mbottom={10}
				/>
				<TextArea
					name="comment"
					placeholder="Комментарий к заявке*"
					onCallBack={(e) => this.onHandleChange(e)}
				/>
				<UploadFile/>
				<CustomCheckBox
					title="Cогласие"
					link="/privacy-policy"
					mtop={20}
					linkTitle="с политикой конфиденциальности"
					checked={this.state.checked}
					onCallBack={(e) => this.setState({checked: e})}
				/>
				{
					this.state.checkedError ? <ErrorMessage title="Вам нужно принять условия политики конфиденциальности"/> : null
				}
				<Button
					width={270}
					mtop={20}
					title="Отправить"
					onCallBack={this.onHandleSubmit}
				/>
			</Fragment>
		);
	}
}

export default Calculate;
