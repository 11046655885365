import React, {PureComponent} from 'react';
import {Link} from "react-router-dom";
import './ProductCard.scss';
import blankPic from './pic/blank-pic.jpg';

class ProductCard extends PureComponent {

	checkCategory = (e) => {
		switch (parseInt(e)) {
			case 1: return '/printing/' + this.props.link;
			case 27: return 'uv-printing/' + this.props.link;
			case 28: return 'polygraphy/' + this.props.link;
			case 29: return 'signboards/' + this.props.link;
			case 30: return 'pos-materials/' + this.props.link;
			case 31: return 'stands/' + this.props.link;
			case 32: return 'contour-cutting/' + this.props.link;
			default: break;
		}
	};

	render() {

		const productPic = {
			backgroundImage: this.props.image ? `url(${this.props.image})` : `url(${blankPic})`,
			backgroundPosition: 'center center',
			backgroundSize: 'cover',
			height: '215px',
			width: '100%',
		};

		return (
			<div className="product_card">
				{
					this.props.product ? (
							<Link to={this.checkCategory(this.props.category)}/>
						) : (
						this.props.slug ? <Link to={'/' + this.props.slug + '/' + this.props.link}/> : <Link to={this.props.link}/>
					)

				}
				<div className="product_card_pic" style={productPic}/>
				<div className="product_card_title">{this.props.title}</div>
				<div className="product_card_price">
					от <span>{this.props.price ? this.props.price : '-'}</span> ₽/{this.props.quantityTitle ? this.props.quantityTitle : '-'}
				</div>
			</div>
		);
	}
}

export default ProductCard;
