import React, {Fragment, Component} from 'react';
import Header from "../components/Header";
import FullHeightBlock from "../components/Elements/FullHeightBlock/FullHeightBlock";
import MainSlider from "../components/Elements/Slider";
import SliderElement from "../components/Elements/Slider/SliderElement";
import SliderImage from "../components/Elements/Slider/pic/slider.jpg";
import MainSliderForm from "../components/Forms/MainSliderForm";
import CenterBlock from "../components/Elements/CenterBlock/CenterBlock";
import Section from "../components/Elements/Section";
import Grid from "../components/Elements/Grid";
import MainBanner from "../components/Elements/MainBanner";
import mainBannerImage1 from '../components/Elements/MainBanner/pic/banner1.jpg';
import mainBannerImage2 from '../components/Elements/MainBanner/pic/banner2.jpg';
import mainBannerImage3 from '../components/Elements/MainBanner/pic/banner3.jpg';
import mainBannerImage4 from '../components/Elements/MainBanner/pic/banner4.jpg';
import ProductCardList from "../components/Elements/ProductCard/ProductCardList";
import {Link} from "react-router-dom";
import MainAboutText from "../components/Elements/MainAboutText";
import PortfolioSlider from "../components/Elements/PortfolioSlider";
import MainAdvantages from "../components/Elements/MainAdvantages";
import {inject, observer} from "mobx-react";
import NewsItem from "../components/Elements/News";
import ConsultationForm from "../components/ConsultationForm";
import Footer from "../components/Footer";
import Loading from "../components/Elements/Loading";

@inject('Store', 'Actions')
@observer
class Main extends Component {

	componentDidMount() {
		const {Store, Actions} = this.props;
		Store.portfolio.status = false;
		Store.portfolio.data = {};
		Store.clients.status = false;
		Store.clients.data = {};
		Store.news.status = false;
		Actions.getPortfolio();
		Actions.getClients();
	}

	render() {

		const {Store} = this.props;

		return (
			<Fragment>

				<FullHeightBlock>
					<Header mainTopHeader={true} main={true}/>
					<MainSlider height="100vh" position="absolute" top={0} background="#000000">
						<SliderElement
							image={SliderImage}
							overlay={true}
							title="Печать визиток"
							subtitle="Офсетная печать визиток от 100 штук. Высокую скорость и качество – Гарантируем!"
							link="/polygraphy/vizitki"
						/>
						<SliderElement
							image={SliderImage}
							overlay={true}
							title="Печать визиток"
							subtitle="Офсетная печать визиток от 100 штук. Высокую скорость и качество – Гарантируем!"
							link="/polygraphy/vizitki"
						/>
						<SliderElement
							image={SliderImage}
							overlay={true}
							title="Печать визиток"
							subtitle="Офсетная печать визиток от 100 штук. Высокую скорость и качество – Гарантируем!"
							link="/polygraphy/vizitki"
						/>
					</MainSlider>
					<MainSliderForm/>
				</FullHeightBlock>
				<CenterBlock>
					<Section ptop={100} id="main_banners">
						<Grid count={2} gap={25}>
							<MainBanner
								image={mainBannerImage2}
								title="Широкоформатная печать"
								link="/printing"
								height={260}
							/>
							<MainBanner
								image={mainBannerImage1}
								title="УФ Печать"
								link="/uv-printing"
								height={260}
							/>
							<MainBanner
								image={mainBannerImage3}
								title="Полиграфия"
								link="/polygraphy"
								height={260}
							/>
							<MainBanner
								image={mainBannerImage4}
								title="Контурная резка"
								link="/contour-cutting"
								height={260}
							/>
						</Grid>
					</Section>
				</CenterBlock>
				<Section
					id="main_products_list"
					title="Наши услуги"
					sectionTitlePadding="0 0 30px 0"
					bg="#F9F9F9"
					padding="40px"
				>
					<CenterBlock>
						<ProductCardList count={8}/>
						<div className="all_products_link">
							<Link to="/product">Вся продукция</Link>
						</div>
					</CenterBlock>
				</Section>
				<Section id="main_about_text">
					<CenterBlock>
						<MainAboutText/>
					</CenterBlock>
				</Section>
				<Section
					id="portfolio"
					title="Наше портфолио"
					bg="#F9F9F9"
					padding="40px"
				>
					<CenterBlock>
						{
							Store.portfolio.status ? (
								<PortfolioSlider
									array={Store.portfolio.data.acf.portfolio}
									arrows={true}
									slidesToScroll={3}
									slidesToShow={3}
									slidesToScrollTablet={2}
									slidesToShowTablet={2}
									allLink="/portfolio"
									allLinkTitle="Все портфолио"
								/>
							) : <Loading center={true}/>
						}
					</CenterBlock>
				</Section>

				<Section
					id="our_advantages"
					title="Наши преимущества"
					padding="40px"
				>
					<CenterBlock>
						<MainAdvantages/>
					</CenterBlock>
				</Section>

				<Section
					id="clients"
					title="Наши клиенты"
					subtitle="Среди наших клиентов крупные и известные компании"
					padding="40px"
				>
					<CenterBlock>
						{
							Store.clients.status ? (
								<PortfolioSlider
									array={Store.clients.data.acf.clients}
									arrows={false}
									slidesToScroll={5}
									slidesToShow={5}
									slidesToScrollTablet={3}
									slidesToShowTablet={3}
									clients={true}
								/>
							) : <Loading center={true}/>
						}

					</CenterBlock>
				</Section>

				<Section
					id="main_review"
					title="Отзывы наших клиентов"
					subtitle="Благодарные отзывы наших клиентов - лучшее подтверждение высокого качества наших работ"
					padding="40px"
					bg="#F9F9F9"
				>
					{/*<CenterBlock>*/}
					{/*	<PortfolioSlider*/}
					{/*		array={Store.portfolio.data.acf.portfolio}*/}
					{/*		arrows={true}*/}
					{/*		slidesToScroll={3}*/}
					{/*		slidesToShow={3}*/}
					{/*		slidesToScrollTablet={2}*/}
					{/*		slidesToShowTablet={2}*/}
					{/*		allLink="/reviews"*/}
					{/*		allLinkTitle="Все отзывы"*/}
					{/*	/>*/}
					{/*</CenterBlock>*/}
				</Section>

				<Section
					mtop={100}
					id="main_news"
					title="Новости компании"
					subtitle="Новости компании, мероприятия, выставки, о наших сотрудниках и достижениях"
				>
					<CenterBlock>
						<Grid count={4} gap={20}>
							{
								Store.news.data.map((item, index) => {
									return (
										index < 4 ? (
											<NewsItem
												key={item.id}
												link={item.slug}
												pic={item.featured_image.large}
												title={item.title}
												date={item.date}
											/>
										) : null
									)
								})
							}
						</Grid>
						<div className="all_news">
							<Link to="/news">Все новости</Link>
						</div>
					</CenterBlock>
				</Section>

				<Section id="main_form">
					<CenterBlock>
						<ConsultationForm/>
					</CenterBlock>
				</Section>

				<Footer/>
			</Fragment>
		)
	}
}

export default Main;
