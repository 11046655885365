import React, {Component, Fragment} from 'react';
import CenterBlock from "../Elements/CenterBlock/CenterBlock";
import Logo from './Logo'
import MainHeader from "./MainHeader";
import Button from "../Forms/Button";
import MainNav from "./MainNav";
import './Header.scss';
import {inject, observer} from "mobx-react";
import ReactDOM from "react-dom";
import Modal from "../Elements/Modal";
import Specialist from "../Elements/Modal/Specialist";
import Calculate from "../Elements/Modal/Calculate";
import TopHeader from "./TopHeader";

@inject('Store', 'Params')
@observer
class Header extends Component{

	orderCalculation = () => {
		const {Store} = this.props;
		Store.order_calculation_modal = true;
	};

	orderCallBack = () => {
		const {Store} = this.props;
		Store.call_modal = true;
	};

	render() {

		const {Store, Params} = this.props;

		return (
			<Fragment>
				{
					Store.call_modal &&
					ReactDOM.createPortal(
						<Modal
							title="Консультация специалиста"
							subtitle="Наш менеджер ответит вам в течении 5 минут"
						>
							<Specialist/>
						</Modal>, document.getElementById('modal')
					)
				}

				{
					Store.order_calculation_modal &&
					ReactDOM.createPortal(
						<Modal
							title="Заказать расчет"
							subtitle="Наш менеджер ответит вам в течении 5 минут"
						>
							<Calculate/>
						</Modal>, document.getElementById('modal')
					)
				}
				<TopHeader mainTopHeader={this.props.mainTopHeader}/>
				<MainHeader main={this.props.main}>
					<CenterBlock>
						<div className="header_top">
							<Logo footer={false}/>
							<div className="header_adress">
								<span>г. Москва</span>
								<span>{Params.data.address}</span>
							</div>
							<div className="header_right_block">
								<div className="header_phone">
									<a href={Params.data.linkPhone}>{Params.data.phone}</a>
									<button onClick={this.orderCallBack}>Заказать звонок</button>
								</div>
								<Button
									title="Заказать расчет"
									width={150}
									onCallBack={this.orderCalculation}
								/>
							</div>
						</div>
						<div className="header_nav">
							<MainNav/>
						</div>
					</CenterBlock>
				</MainHeader>
			</Fragment>
		)
	}
}

export default Header;
