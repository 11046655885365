import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class AboutCounter extends PureComponent {
	render() {
		return (
			<div className="about_counter_item">
				<span>{this.props.count}</span>
				<span>{this.props.title}</span>
			</div>
		);
	}
}

AboutCounter.propTypes = {
	count: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
};

export default AboutCounter;
