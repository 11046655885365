import React, {Fragment, PureComponent} from 'react';
import './Modal.scss';
import PropTypes from 'prop-types';
import closeButton from './close.svg';
import {inject, observer} from "mobx-react";
import modalPic from './pic/consult-icon.jpg';

@inject('Store')
@observer
class Modal extends PureComponent {

	onHandleClick = () => {
		const {Store} = this.props;
		Store.call_modal = false;
		Store.order_calculation_modal = false;
	};

	render() {
		return (
			<Fragment>
				<div className="modal">
					<button
						className="modal_close"
						onClick={this.onHandleClick}
					>
						<img src={closeButton} alt="Закрыть окно"/>
					</button>
					<div className="modal_position">
						<div className="modal_left">
							<div className="modal_pic"><img src={modalPic} alt="Консультация специалиста"/></div>
							<div className="modal_name">Сафронова Екатерина</div>
							<div className="modal_profession">Ведущий специалист</div>
							<div className="modal_phone"><a href="tel:84955098098">+7 (495) 509-80-98</a></div>
							<div className="modal_email"><a href="mailto:5098098@mail.ru">5098098@mail.ru</a></div>
						</div>
						<div className="modal_right">
							<div className="modal_title">{this.props.title}</div>
							<div className="modal_subtitle">{this.props.subtitle}</div>
							{this.props.children}
						</div>
					</div>
				</div>
				<div className="modal_overlay" onClick={this.onHandleClick}/>
			</Fragment>
		);
	}
}

Modal.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
};

export default Modal;
