import React, {PureComponent} from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import './PhoneInput.scss';

class PhoneInput extends PureComponent {

	state = {
		value: ''
	};

	componentDidMount() {
		if (this.props.value) {
			this.setState({value: this.props.value})
		}
	};

	onHandleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		const obj = {
			[name]: value
		};
		this.setState({value}, () => this.props.onCallBack(obj))
	};

	render() {

		const style = {
			marginBottom: this.props.mbottom,
			marginTop: this.props.mtop,
		};

		return (
			<div style={style} className="smart_phonemask">
				<InputMask
					mask="8 (999) 999-99-99"
					type={this.props.type}
					name={this.props.name}
					value={this.state.value}
					placeholder={this.props.placeholder}
					onChange={this.onHandleChange}
				/>
			</div>
		);
	}
}

PhoneInput.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	mbottom: PropTypes.number,
	mtop: PropTypes.number,
	placeholder: PropTypes.string,
	onCallBack: PropTypes.func.isRequired,
};

export default PhoneInput;
