import React, {Component} from 'react';
import {Link} from "react-router-dom";
import './CustomCheckBox.scss';
import PropTypes from 'prop-types';

class CustomCheckBox extends Component{

	state = {
		checked: false,
	};

	componentDidMount() {
		if (this.props.checked) {
			this.setState({checked: this.props.checked},
				() => this.props.onCallBack(this.state.checked))
		}
	}

	render() {

		const style = {
			marginTop: this.props.mtop,
		};

		return (
			<div className="custom_checkbox" style={style}>
				<label>
					<input
						type="checkbox"
						checked={this.state.checked}
						onChange={() => this.setState({checked: !this.state.checked},
							() => this.props.onCallBack(this.state.checked))}
					/>
					{
						this.props.link ?
							<span><p>{this.props.title}</p> <Link to={this.props.link}>{this.props.linkTitle}</Link></span> :
							<span>{this.props.title}</span>
					}
				</label>
			</div>
		)
	}
}

export default CustomCheckBox;
CustomCheckBox.propTypes = {
	title: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	linkTitle: PropTypes.string,
	link: PropTypes.string,
	mtop: PropTypes.number,
};
