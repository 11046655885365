import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "mobx-react";
import App from './components/App';
import Store from './store/Store';
import Actions from './store/Actions';
import Params from './store/Params';
import Mail from './store/Mail';
import SuccessMessage from "./components/SuccessMessage";

const Root = (
	<Provider
		Store={Store}
		Actions={Actions}
		Params={Params}
		Mail={Mail}
	>
			<SuccessMessage/>
			<App />
	</Provider>
);

ReactDOM.render(Root, document.getElementById('root'));
