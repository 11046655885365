import React, {Component} from 'react';
import './Button.scss';
import PropTypes from 'prop-types';

class Button extends Component{
	render() {

		const style = {
			maxWidth: this.props.width,
			width: '100%',
			marginTop: this.props.mtop,
			marginBottom: this.props.mbottom,
		};

		return (
			this.props.center ? (
					<div className="center_button">
						<button
							className="submit_button"
							style={style}
							onClick={this.props.onCallBack}
						>{this.props.title}</button>
					</div>
				) : (
				<button
					className="submit_button"
					style={style}
					onClick={this.props.onCallBack}
				>{this.props.title}</button>
			)

		)
	}
}

export default Button;

Button.propTypes = {
	center: PropTypes.bool,
	width: PropTypes.any,
	mtop: PropTypes.number,
	marginBottom: PropTypes.number,
	title: PropTypes.string.isRequired,
	onCallBack: PropTypes.any.isRequired,
};
