import React, {Fragment, PureComponent} from 'react';
import Input from "../../Forms/Input";
import PhoneInput from "../../Forms/PhoneInput";
import CustomCheckBox from "../../Forms/CustomCheckBox";
import Button from "../../Forms/Button";
import ErrorMessage from "../../Forms/ErrorMessage";
import {inject, observer} from "mobx-react";

@inject('Store', 'Mail')
@observer
class Specialist extends PureComponent {

	state = {
		name: '',
		phone: '',
		email: '',
		checked: false,
		checkedError: false,
		validateError: false,
	};

	onHandleChange = (e) => {
		this.setState({...this.state, ...e})
	};

	onHandleSubmit = () => {
		const {Mail} = this.props;
		this.setState({checkedError: false, validateError: false});
		const phone = this.state.phone;
		const maskPhone = phone.replace(/[-+_()\s]/g, '');
		if (this.state.checked) {
			if (this.state.name.length > 0 && this.state.email.length > 0 && maskPhone.length === 11) {
				const data = {
					'your-name': this.state.name,
					'your-email': this.state.email,
					'your-phone': maskPhone,
				};
				Mail.specialistConsultation(data)
			} else {
				this.setState({validateError: true})
			}
		} else {
			this.setState({checkedError: true})
		}
	};

	render() {
		return (
			<Fragment>
				<Input
					type="text"
					name="name"
					value={this.state.name}
					placeholder="Ваше имя"
					mbottom={10}
					onCallBack={(e) => this.onHandleChange(e)}
				/>
				<Input
					type="email"
					name="email"
					placeholder="Ваш Email"
					value={this.state.email}
					mbottom={10}
					onCallBack={(e) => this.onHandleChange(e)}
				/>
				<PhoneInput
					type="text"
					name="phone"
					placeholder="Укажите номер телефона"
					onCallBack={e => this.onHandleChange(e)}
					mbottom={10}
				/>
				<CustomCheckBox
					title="Cогласие"
					link="/privacy-policy"
					mtop={20}
					linkTitle="с политикой конфиденциальности"
					checked={this.state.checked}
					onCallBack={(e) => this.setState({checked: e})}
				/>
				{
					this.state.checkedError ? <ErrorMessage title="Вам нужно принять условия политики конфиденциальности"/> : null
				}
				{
					this.state.validateError ? <ErrorMessage title="Ошибка заполнения полей! Проверьте заполнение!"/> : null
				}
				<Button
					width={270}
					mtop={20}
					title="Отправить"
					onCallBack={this.onHandleSubmit}
				/>
			</Fragment>
		);
	}
}

export default Specialist;
