import {action} from "mobx";
import axios from 'axios';
import Store from './Store';

class Actions {

	// Получаем основное меню
	@action getNav = () => {
		const url = '/menus/v1/menus/main-menu';
		axios.get(url)
			.then(response => Store.nav = response.data.items);
	};

	// Получаем рубрики
	@action getProductCategory = () => {
		const url = '/wp/v2/categories/';
		axios.get(url)
			.then(response => Store.product_cat = response.data);
	};

	// Получаем список постов рубрики продукции
	@action getCategoryNav = (slug) => {
		const url = '/wl/v1/posts/' + slug;
		axios.get(url)
			.then(response => Store.product_post_link = response.data);
	};

	// Получаем список всех продуктов
	@action getAllProducts = () => {
		const url = '/wl/v1/products/';
		axios.get(url)
			.then(response => Store.products = response.data);
	};

	@action getSlugProducts = (slug) => {
		const url = '/wl/v1/product/' + slug;
		axios.get(url)
			.then(response => Store.products = response.data);
	};

	@action getProduct = (id) => {
		const url = '/wp/v2/posts/' + id;
		axios.get(url)
			.then(response => {
				Store.product.data = response.data;
				Store.product.status = response.status === 200;
			});
	};

	// Получаем все рубрики и подрубрики
	@action getAllProductsNav = () => {
		const url = '/menus/v1/menus/products';
		axios.get(url)
			.then(response => Store.product_post_link = response.data.items);
	};

	// Получаем все рубрики и подрубрики
	@action getAllProductsRouter = () => {
		const url = '/menus/v1/menus/products';
		axios.get(url)
			.then(response => Store.router_links = response.data.items);
	};

	// Получаем все страницы
	@action getPages = () => {
		const url = '/wp/v2/pages/?per_page=40';
		axios.get(url)
			.then(response => Store.pages = response.data);
	};

	// Получаем определенную страницу
	@action getPage = (id) => {
		const url = '/wp/v2/pages/' + id;
		axios.get(url)
			.then(response => {
				Store.page.data = response.data;
				Store.page.status = response.status === 200;
			});
	};

	// Получаем определенную страницу
	@action getPortfolio = () => {
		const url = '/wp/v2/pages/1328';
		axios.get(url)
			.then(response => {
				Store.portfolio.data = response.data;
				Store.portfolio.status = response.status === 200;
			});
	};

	// Получаем список клиентов
	@action getClients = () => {
		const url = '/wp/v2/pages/1347';
		axios.get(url)
			.then(response => {
				Store.clients.data = response.data;
				Store.clients.status = response.status === 200;
			});
	};

	@action getAdvantages = () => {
		const url = '/wp/v2/pages/1360';
		axios.get(url)
			.then(response => {
				Store.advantages.data = response.data;
				Store.advantages.status = response.status === 200;
			});
	};

	@action getNews = () => {
		const url = 'wl/v1/posts/news';
		axios.get(url)
			.then(response => {
				Store.news.data = response.data;
				Store.news.status = response.status === 200;
			});
	};

	@action getPost = (id) => {
		const url = '/wp/v2/posts/' + id;
		axios.get(url)
			.then(response => {
				Store.post.data = response.data;
				Store.post.status = response.status === 200;
			});
	};

}

const store = new Actions();
export default store;
