import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class MainHeader extends PureComponent {
	render() {
		return (
			<header className={this.props.main ? 'main_header' : 'full_header'}>
				{this.props.children}
			</header>
		);
	}
}

MainHeader.propTypes = {
	main: PropTypes.bool.isRequired,
};

export default MainHeader;
