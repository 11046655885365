import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Input.scss';

class Input extends Component{

	state = {
		value: '',
	};

	componentDidMount() {
		if (this.props.value) {
			this.setState({
				value: this.props.value,
			})
		}
	}

	onHandleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		const obj = {
			[name]: value
		};
		this.setState({value}, () => this.props.onCallBack(obj))
	};

	render() {

		const style = {
			marginBottom: this.props.mbottom,
			marginTop: this.props.mtop,
			width: this.props.width ? this.props.width : '100%'
		};

		return (
			<input
				className={this.props.transparent === true ? 'smart_input_transparent' : 'smart_input'}
				style={style}
				type={this.props.type}
				name={this.props.name}
				placeholder={this.props.placeholder}
				value={this.state.value}
				onChange={this.onHandleChange}
				autoComplete="nope"
			/>
		);
	}
}

export default Input;

Input.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	mbottom: PropTypes.number,
	mtop: PropTypes.number,
	placeholder: PropTypes.string,
	onCallBack: PropTypes.func.isRequired,
	width: PropTypes.number,
};
