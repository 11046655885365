import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import {Link} from "react-router-dom";
import './PortfolioSlider.scss';

class PortfolioSlider extends PureComponent {

	render() {

		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			arrows: this.props.arrows,
			slidesToShow: this.props.slidesToShow,
			slidesToScroll: this.props.slidesToScroll,
			adaptiveHeight: true,
			autoplay: true,
			pauseOnFocus: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: this.props.slidesToShowTablet,
						slidesToScroll: this.props.slidesToScrollTablet,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: false,
					}
				},
			]
		};

		return (

			<Fragment>
				<Slider {...settings}>
					{
						this.props.array ? (
							this.props.array.map((item, index) => {
								return (
									<div key={index} className={this.props.clients === true ? 'clients_slider_item' : 'portfolio_slider_item'}>
										{
											this.props.clients ? (
												<span>
													<img src={item.pic ? item.pic : item} alt={item.title}/>
												</span>
											) : <img src={item.pic ? item.pic : item} alt={item.title}/>
										}
										{
											this.props.title ? <span>{item.title}</span> : null
										}
									</div>
								)
							})
						) : null
					}
				</Slider>
				{
					this.props.allLinkTitle ? (
						<div className="all_portfolio">
							<Link to={this.props.allLink}>{this.props.allLinkTitle}</Link>
						</div>
					) : null
				}
			</Fragment>
		);
	}
}

PortfolioSlider.propTypes = {
	array: PropTypes.array.isRequired,
	arrows: PropTypes.bool.isRequired,
	slidesToShow: PropTypes.number.isRequired,
	slidesToScroll: PropTypes.number.isRequired,
	slidesToShowTablet: PropTypes.number.isRequired,
	slidesToScrollTablet: PropTypes.number.isRequired,
	allLink: PropTypes.string,
	allLinkTitle: PropTypes.string,
};

export default PortfolioSlider;
