import React, {PureComponent} from 'react';
import CenterBlock from "../Elements/CenterBlock/CenterBlock";
import {NavLink} from "react-router-dom";

class TopHeader extends PureComponent {
	render() {
		return (
			<div className={this.props.mainTopHeader === true ? 'top_header_main' : 'top_header'}>
				<CenterBlock ptop={0} pbottom={0}>
					<ul>
						<li><NavLink to="/news">Новости</NavLink></li>
						<li><NavLink to="/equipment">Оборудование</NavLink></li>
						<li><NavLink to="/faq">Вопрос-Ответ</NavLink></li>
						<li><NavLink to="/layout-requirements">Требования к макетам</NavLink></li>
					</ul>
				</CenterBlock>
			</div>
		);
	}
}

export default TopHeader;
