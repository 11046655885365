import React, {PureComponent, Fragment} from 'react';
import './MainAdvantages.scss';
import MainAdvantagesItem from "./MainAdvantagesItem";
import line from './line.svg';
import Button from "../../Forms/Button";
import {inject, observer} from "mobx-react";
import Loading from "../Loading";

@inject('Store', 'Actions')
@observer
class MainAdvantages extends PureComponent {

	componentDidMount() {
		const {Store, Actions} = this.props;
		Store.advantages.status = false;
		Store.advantages.data = {};
		Actions.getAdvantages();
	}

	onHandleClick = () => {
		const {Store} = this.props;
		Store.order_calculation_modal = true
	};

	render() {

		const {Store} = this.props;

		return (
			<Fragment>
				<div className="main_advantages_list">
					{
						Store.advantages.status ? (
							Store.advantages.data.acf.preimushhestva.map((item, index) => {
								return <MainAdvantagesItem key={index} title={item.title} text={item.description}/>
							})
						) : <Loading center={true}/>
					}
				</div>
				<div className="main_adv_bottom">
					<img src={line} alt="Наши преимущества"/>
				</div>
				<Button
					mtop={40}
					title="Оставить заявку"
					width={200}
					center={true}
					onCallBack={this.onHandleClick}
				/>
			</Fragment>
		);
	}
}

export default MainAdvantages;
