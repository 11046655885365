import React, {Component} from 'react';
import './CenterBlock.scss';
import PropTypes from 'prop-types';
import Footer from "../../Footer";

class CenterBlock extends Component{

	render() {

		const style = {
			paddingTop: this.props.ptop === 0 ? this.props.ptop : '60px',
			paddingBottom: this.props.pbottom === 0 ? this.props.pbottom: '60px',
			paddingLeft: this.props.pleft === 0 ? this.props.pleft: '20px',
			paddingRight: this.props.pright === 0 ? this.props.pright: '20px',
		};

		return (
			<div style={style} className="center_block">
				{this.props.children}
			</div>
		)
	}
}

Footer.propTypes = {
	ptop: PropTypes.number,
	pbottom: PropTypes.number,
	pleft: PropTypes.number,
	pright: PropTypes.number,
};

export default CenterBlock;
