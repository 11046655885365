import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {clickOutside} from "@epranka/react-click-outside";
import {inject, observer} from "mobx-react";

@inject('Store', 'Actions')
@observer
class Menu extends Component {

	state = {
		visible: 'none',
		active: false,
	};

	onClickOutside(e) {
		this.setState({
			visible: 'none',
			active: false,
		})
	}

	onHandleClick = () => {
		this.setState({visible: 'none'})
	};

	render() {

		const {Store} = this.props;

		const style = {
			display: this.state.visible
		};

		return (
			this.props.dropArray ? (
				<div
					onMouseLeave={() => this.setState({visible: 'none', active: false})}
					onMouseOver={() => this.setState({visible: 'block', active: true})}
					className={this.state.active ? "drop_nav active_drop" : "drop_nav"}>
					<NavLink
						className={Store.active_product_link ? 'active' : null}
						to={this.props.product ? this.props.link : ('/' + this.props.link)}>
						{this.props.title}
					</NavLink>
					<ul style={style}>
						{
							this.props.dropArray ? (
								this.props.dropArray.map(item => {
									return (
										<li key={item.title + item.ID}>
											<NavLink onClick={this.onHandleClick} to={'/' + item.slug}>{item.title}</NavLink>
										</li>
									)
								})
							) : null
						}
					</ul>
				</div>
			) : (
				<div className="element_nav">
					<NavLink to={'/' + this.props.link}>{this.props.title}</NavLink>
				</div>
			)
		)
	}
}

export default clickOutside(Menu);
