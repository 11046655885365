import React, {Fragment, PureComponent} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import CenterBlock from "../components/Elements/CenterBlock/CenterBlock";
import {inject, observer} from "mobx-react";
import Interweave from "interweave";
import Loading from "../components/Elements/Loading";

@inject('Store', 'Actions')
@observer
class FullNews extends PureComponent {

	componentDidMount() {
		const {Store, Actions} = this.props;
		Store.page.status = false;
		Store.order_calculation_modal = false;
		Store.call_modal = false;
		Actions.getPost(this.props.id);
	}

	componentWillUnmount() {
		const {Store} = this.props;
		Store.page = {
			status: false,
			data: {}
		};
	}

	render() {

		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.post.data.featured_image_url})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center center',
			width: '100%',
			height: '300px',
		};

		return (
			<Fragment>
				<Header main={false}/>
				<CenterBlock>
					{
						Store.post.status ? (
							<div className="content_page_block">
								<h1>{Store.post.data.title.rendered}</h1>
								<div className="content_paragraph">
									<div className="news_pic" style={style}/>
									<Interweave content={Store.post.data.content.rendered} />
								</div>
							</div>
						) : <Loading fullHeight={true}/>
					}
				</CenterBlock>
				<Footer/>
			</Fragment>
		);
	}
}

export default FullNews;
