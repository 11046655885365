import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

class Loading extends PureComponent {

	render() {

		const style = {
			height: this.props.fullHeight ? 'calc(100vh - 778px)' : 'auto',
		};

		return (
			this.props.center === true ? (
				<div className="loading_center" style={style}>
					<div className="loading_spinner">
						<div className="loading_block">
							<div/>
							<div/>
						</div>
					</div>
				</div>
			) : (
				<div className="loading_spinner" style={style}>
					<div className="loading_block">
						<div/>
						<div/>
					</div>
				</div>
			)
		);
	}
}

Loading.propTypes = {
	fullHeight: PropTypes.bool,
	center: PropTypes.bool,
};

export default Loading;
