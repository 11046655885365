import React, {PureComponent} from 'react';
import icon from './pic/consult-icon.jpg';
import Input from "../Forms/Input";
import './ConsultationForm.scss';
import TextArea from "../Forms/TextArea";
import CustomCheckBox from "../Forms/CustomCheckBox";
import Button from "../Forms/Button";
import ErrorMessage from "../Forms/ErrorMessage";

class ConsultationForm extends PureComponent {

	state = {
		name: '',
		phone: '',
		comment: '',
		email: '',
		checked: false,
		checkedError: false,
	};

	onHandleChange = (e) => {
		this.setState({...this.state, ...e}, () => console.log(this.state))
	};

	onHandleSubmit = () => {
		if (this.state.checked) {
			this.setState({checkedError: false});
			console.log('send message')
		} else {
			this.setState({checkedError: true})
		}
	};

	render() {
		return (
			<div className="consultation_form">
				<div className="consultation_form_icon">
					<img src={icon} alt="Консультация специалиста"/>
					<div className="consult_name">Сафронова Екатерина</div>
					<div className="consult_work_position">Ведущий специалист</div>
					<div className="consult_phone"><a href="tel:+74955098098">+7 (495) 509-80-98</a></div>
					<div className="consult_email"><a href="mailto:5098098@mail.ru">5098098@mail.ru</a></div>
				</div>
				<div className="consultation_form_content">
					<div className="consult_title">Консультация специалиста</div>
					<div className="consult_subtitle">Наш менеджер ответит вам в течении 5 минут</div>
					<Input
						type="text"
						name="name"
						value={this.state.name}
						placeholder="Ваше имя"
						onCallBack={(e) => this.onHandleChange(e)}
					/>
					<Input
						type="email"
						name="email"
						placeholder="Ваш Email"
						value={this.state.email}
						onCallBack={(e) => this.onHandleChange(e)}
					/>
					<Input
						type="phone"
						name="phone"
						placeholder="Ваш номер телефона*"
						value={this.state.phone}
						onCallBack={(e) => this.onHandleChange(e)}
					/>
					<TextArea
						name="comment"
						placeholder="Комментарий к заявке*"
						onCallBack={(e) => this.onHandleChange(e)}
					/>
					<CustomCheckBox
						title="Cогласие"
						link="/privacy-policy"
						mtop={20}
						linkTitle="с политикой конфиденциальности"
						checked={this.state.checked}
						onCallBack={(e) => this.setState({checked: e})}
					/>
					{
						this.state.checkedError ? <ErrorMessage title="Вам нужно принять условия политики конфиденциальности"/> : null
					}
					<Button
						width={270}
						mtop={20}
						title="Отправить"
						onCallBack={this.onHandleSubmit}
					/>
				</div>
			</div>
		);
	}
}

export default ConsultationForm;
