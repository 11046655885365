import React, {Fragment, PureComponent} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {inject, observer} from "mobx-react";
import Loading from "../components/Elements/Loading";
import CenterBlock from "../components/Elements/CenterBlock/CenterBlock";
import Interweave from "interweave";

@inject('Store', 'Actions')
@observer
class Page extends PureComponent {

	componentDidMount() {
		const {Store, Actions} = this.props;
		Store.page.status = false;
		Store.order_calculation_modal = false;
		Store.call_modal = false;
		Actions.getPage(this.props.id);
	}

	componentWillUnmount() {
		const {Store} = this.props;
		Store.page = {
			status: false,
			data: {}
		};
	}

	render() {
		const {Store} = this.props;
		return (
			<Fragment>
				<Header main={false}/>
				<CenterBlock>
					{
						Store.page.status ? (
							<div className="content_page_block">
								<h1>{Store.page.data.title.rendered}</h1>
								<div className="content_paragraph">
									<Interweave content={Store.page.data.content.rendered} />
									{
										this.props.slug === 'faq' ? (
											Store.page.data.acf.faq ? (
												Store.page.data.acf.faq.map(item => {
													return (
														<div className="faq_item">
															<div className="faq_title">{item.vopros}</div>
															<div className="faq_content">
																<Interweave content={item.otvet} />
															</div>
														</div>
													)
												})
											) : null
										) : null
									}
								</div>
							</div>
						) : <Loading fullHeight={true}/>
					}
				</CenterBlock>
				<Footer/>
			</Fragment>
		);
	}
}

export default Page;
