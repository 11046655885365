import React, {Fragment, PureComponent} from 'react';
import './Pagination.scss';
import {Link} from "react-router-dom";

class Pagination extends PureComponent {
	render() {
		return (
			<div className="pagination_block">
				<ul>
					<li><Link to="/">Главная</Link></li>

					{
						this.props.title === 'Услуги' ? null : (
							<Fragment>
								<li><Link to="/product">Услуги</Link></li>
								{
									this.props.itemTitle ? (<li><Link to={'/' + this.props.slug}>{this.props.title}</Link></li>) : (
										<li>{this.props.title}</li>
									)
								}
								{
									this.props.itemTitle ? <li>{this.props.itemTitle}</li> : null
								}
							</Fragment>
						)
					}
				</ul>
			</div>
		);
	}
}

export default Pagination;
