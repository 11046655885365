import React from 'react';
import PropTypes from 'prop-types';
import './Slider.scss';
import {Link} from "react-router-dom";
import CenterBlock from "../CenterBlock/CenterBlock";

const SliderElement = ({image, title, subtitle, link, overlay}) => {

	const style = {
		backgroundImage: 'url(' + image +')',
	};

	return (
		<div className="slider_item" style={style}>
			<CenterBlock>
				<div className="slider_item_content">
					<div className="slider_item_title">{title}</div>
					<div className="slider_item_subtitle">{subtitle}</div>
					<Link to={link}>Подробнее</Link>
				</div>
			</CenterBlock>
			{overlay ? <div className="slider_item_overlay"/> : null}
		</div>
	);
};

export default SliderElement;

SliderElement.defaultProps = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	link: PropTypes.string,
};
