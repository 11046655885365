import React, {PureComponent} from 'react';
import './Footer.scss';
import CenterBlock from "../Elements/CenterBlock/CenterBlock";
import Logo from "../Header/Logo";
import Button from "../Forms/Button";
import {NavLink} from "react-router-dom";
import {inject, observer} from "mobx-react";
import Loading from "../../components/Elements/Loading";

@inject('Store', 'Actions', 'Params')
@observer
class Footer extends PureComponent {

	RequestCall = () => {
		const {Store} = this.props;
		Store.call_modal = true;
	};

	componentDidMount() {
		const {Actions} = this.props;
		Actions.getProductCategory();
	}

	onHandleClick = (link) => {
		const {Actions, Store} = this.props;
		Actions.getCategoryNav(link);
		Store.product_link = link;
		this.setState({visible: 'none'})
	};

	render() {

		const {Store, Params} = this.props;

		return (
			<footer>
				<div className="footer_top">
					<CenterBlock pleft={60} pright={60} pbottom={0} ptop={0}>
						<div className="footer_position">
							<div className="footer_left_block">
								<Logo footer={true}/>
								<div className="footer_slogan">Типография, полиграфические услуги в Москве и области</div>
								<div className="footer_phone">
									<a href={Params.data.linkPhone}>{Params.data.phone}</a>
								</div>
								<Button mtop={10} title="Заказать звонок" onCallBack={this.RequestCall}/>
								<div className="footer_email">
									<a href={Params.data.emailLink}>{Params.data.email}</a>
								</div>
								<div className="footer_adress">
									<a
										href="https://yandex.ru/maps/213/moscow/house/pyatnitskoye_shosse_53/Z04YdQNmSUYOQFtvfXR3cHhkbQ==/?ll=37.346038%2C55.861479&sll=37.346102%2C55.861281&sspn=0.005568%2C0.001685&z=17.55">г. Москва, {Params.data.address}</a>
								</div>
							</div>
							<div className="footer_right_block">
								<div className="footer_nav_item">
									<div className="footer_nav_item_title">Компания</div>
									<ul>
										<li><NavLink to="/about">О компании</NavLink></li>
										<li><NavLink to="/news">Новости</NavLink></li>
										<li><NavLink to="/equipment">Оборудование</NavLink></li>
										<li><NavLink to="/faq">Вопрос-Ответ</NavLink></li>
										<li><NavLink to="/portfolio">Портфолио</NavLink></li>
										<li><NavLink to="/reviews">Отзывы</NavLink></li>
										<li><NavLink to="/layout-requirements">Требования к макетам</NavLink></li>
										<li><NavLink to="/design">Дизайн студия</NavLink></li>
										<li><NavLink to="/delivery">Доставка</NavLink></li>
										<li><NavLink to="/contacts">Контакты</NavLink></li>
									</ul>
								</div>

								<div className="footer_nav_item">
									<div className="footer_nav_item_title">Услуги</div>
									<ul>
										{
											Store.product_cat.length ? (
												Store.product_cat.map(item => {
													return (
														item.slug === 'news' ? null : (
															<li key={item.id}>
																<NavLink
																	onClick={() => this.onHandleClick(item.slug)}
																	to={item.slug}>
																	{item.name}
																</NavLink>
															</li>
														)
													)
												})
											) : <Loading/>
										}
									</ul>
								</div>
							</div>
						</div>
					</CenterBlock>
				</div>
				<div className="footer_bottom">
					<CenterBlock pleft={60} pright={60} pbottom={0} ptop={0}>
						<div className="copyright_footer">2020 © Все права защищены</div>
						<div className="footer_bottom_links">
							<ul>
								<li><NavLink to="/privacy-policy">Политика конфиденциальности</NavLink></li>
								<li><NavLink to="/">Карта сайта</NavLink></li>
							</ul>
						</div>
					</CenterBlock>
				</div>
			</footer>
		);
	}
}

export default Footer;
