import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './App.scss';
import Main from "../../pages/Main";
import News from "../../pages/News";
import FullNews from "../../pages/FullNews";
import Product from "../../pages/Product";
import FullProduct from "../../pages/FullProduct";
import Page from '../../pages/Page'
import Protfolio from '../../pages/Protfolio';
import {inject, observer} from "mobx-react";
import axios from 'axios';
import ScrollToTop from "../ScrollToTop";

@inject('Store', 'Actions')
@observer
class App extends Component {

	componentDidMount() {
		const {Actions} = this.props;
		Actions.getAllProductsRouter();
		Actions.getPages();
		Actions.getNews();
	}

	render() {

		const {Store} = this.props;
		axios.defaults.baseURL = 'https://gmadv.ru/wp-json';

		return (
			<Router>
				<ScrollToTop/>
				<Switch>
					<Route exact path="/" component={Main}/>
					<Route exact path="/news" component={News}/>
					{
						// Роутинг всех новостей
						Store.news.data.map(item => {
							return (
								<Route key={item.id} path={'/news/' + item.slug}>
									<FullNews slug={item.slug} id={item.id}/>
								</Route>
							)
						})
					}

					{
						// Роутинг страниц
						Store.pages.map(item => {
							return (
								item.slug === 'portfolio' ? (
									<Route key={item.id} exact path={'/' + item.slug}>
										<Protfolio
											title={item.title.rendered}
											id={item.id}
											slug={item.slug}
										/>
									</Route>
									) : (
									<Route key={item.id} path={'/' + item.slug}>
										<Page
											title={item.title.rendered}
											id={item.id}
											slug={item.slug}
										/>
									</Route>
									)

							)
						})
					}

					{
						Store.router_links.map(item => {
							return (
								<Route
									key={item.ID}
									exact
									path={'/' + item.slug}
								>
									<Product
										id={item.object_id}
										slug={item.slug}
										title={item.title}
									/>
								</Route>
							)
						})
					}
					{
						Store.router_links.map(item => {
							const title = item.title;
							const slug = item.slug;
							return (
								item.child_items.map(item => {
									return (
										<Route
											key={item.ID}
											exact
											path={'/' + slug + '/' + item.slug}
										>
											<FullProduct
												key={item.ID}
												id={item.object_id}
												itemTitle={item.title}
												itemSlug={item.slug}
												title={title}
												slug={slug}
											/>
										</Route>
									)
								})
							)
						})
					}


					<Route path="/product"><Product title="Услуги" slug="product"/></Route>

				</Switch>
			</Router>
		);
	}
}

export default App;
