import React, {PureComponent} from 'react';
import ProductCard from "./index";
import Grid from "../Grid";
import PropTypes from 'prop-types';
import {inject, observer} from "mobx-react";
import Loading from "../Loading";

@inject('Store', 'Actions')
@observer
class ProductCardList extends PureComponent {

	state = {
		array: []
	};

	componentDidMount() {
		const {Actions} = this.props;
		Actions.getAllProducts();
		setTimeout(() => {
			this.randArray();
		}, 500)
	}

	randArray = () => {
		const {Store} = this.props;
		this.setState({
			array: Store.products.slice().sort(() => Math.random() - 0.5)
		})
	};

	render() {

		return (
			<Grid count={4} gap={40}>
				{
					this.state.array.length ? (
						this.state.array.map((item, index) => {
							return (
								index < this.props.count ? (
									<ProductCard
										key={item.id}
										slug={this.props.slug}
										image={item.featured_image.large}
										title={item.title}
										price={item.acf.price}
										quantityTitle={item.acf.quantity}
										link={item.slug}
										category={item.categories[0]}
										product={true}
									/>
								) : null
							)
						})
					) : <Loading center={true}/>

				}
			</Grid>
		);
	}
}

ProductCardList.propTypes = {
	count: PropTypes.number.isRequired,
};

export default ProductCardList;
