import React, {Fragment, PureComponent} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import CenterBlock from "../components/Elements/CenterBlock/CenterBlock";
import {inject, observer} from "mobx-react";
import NewsItem from "../components/Elements/News";
import Grid from "../components/Elements/Grid";
import Loading from "../components/Elements/Loading";

@inject('Store', 'Actions')
@observer
class News extends PureComponent {

	componentDidMount() {
		const {Actions, Store} = this.props;
		Store.news.status = false;
		Actions.getNews();
	}

	render() {

		const {Store} = this.props;

		return (
			<Fragment>
				<Header main={false}/>
				<CenterBlock>
					<div className="content_page_block">
						<h1>Новости GMADV</h1>
						<div className="news_list">
							<Grid count={4} gap={20}>
								{
									Store.news.status ? (
										Store.news.data.map(item => {
											return (
												<NewsItem
													key={item.id}
													id={item.id}
													link={item.slug}
													pic={item.featured_image.large}
													title={item.title}
													date={item.date}
												/>
											)
										})
									) : <Loading fullHeight={true}/>
								}
							</Grid>
						</div>
					</div>

				</CenterBlock>
				<Footer/>
			</Fragment>
		);
	}
}

export default News;
