import React, {Fragment, PureComponent} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import {inject, observer} from "mobx-react";
import ProductNav from "../components/ProductNav";
import CenterBlock from "../components/Elements/CenterBlock/CenterBlock";
import Pagination from "../components/Pagination";
import Loading from "../components/Elements/Loading";
import Interweave from 'interweave';
import Calculator from "../components/Calculator";
import ProductGallery from "../components/ProductGallery";

@inject('Actions', 'Store')
@observer
class FullProduct extends PureComponent {

	componentDidMount() {
		const {Store} = this.props;
		Store.active_product_link = true;
		this.checkLink();
	}

	checkLink = () => {
		const {Actions} = this.props;
		if (this.props.slug === 'product') {
			Actions.getAllProductsNav();
		} else {
			Actions.getCategoryNav(this.props.slug)
		}
		Actions.getProduct(this.props.id);
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.slug !== prevProps.slug) {
			this.checkLink();
		}
	}

	componentWillUnmount() {
		const {Store} = this.props;
		Store.active_product_link = false;
		Store.product = {
			status: false,
			data: {}
		}
	}

	render() {

		const {Store} = this.props;

		return (
			<Fragment>
				<Header main={false}/>
				<CenterBlock>
					<div className="content_position">
						<div className="content_left">
							<ProductNav slug={this.props.slug}/>
						</div>
						<div className="content_right">
							<Pagination
								title={this.props.title}
								itemTitle={this.props.itemTitle}
								slug={this.props.slug}
							/>
							<h1>{this.props.itemTitle}</h1>
							<div className="content_block">
								{
									Store.product.status === true ? (
										<Fragment>
											<Calculator
												image={Store.product.data.featured_image_url}
												step1_area={Store.product.data.acf['1metr']}
												step2_area={Store.product.data.acf['2metr']}
												step3_area={Store.product.data.acf['3metr']}
												step1_price={Store.product.data.acf['1price']}
												step2_price={Store.product.data.acf['2price']}
												step3_price={Store.product.data.acf['3price']}
												step_max={Store.product.data.acf.maximum}
												quantity={Store.product.data.acf.quantity}
												title={Store.product.data.title.rendered}
											/>
											<div className="content_paragraph">
												<Interweave content={Store.product.data.content.rendered} />
											</div>
											{
												Store.product.data.acf.gallery ? (
													<ProductGallery
														array={Store.product.data.acf.gallery}
														title={Store.product.data.title.rendered}
													/>
												) : null
											}
										</Fragment>
									) : <Loading/>
								}
							</div>
						</div>
					</div>
				</CenterBlock>
				<Footer/>
			</Fragment>
		);
	}
}

export default FullProduct;
