import {action} from "mobx";
import axios from 'axios';
import qs from 'qs';
import Store from './Store';

class Mail {

	@action specialistConsultation = (...props) => {
		const url = 'contact-form-7/v1/contact-forms/1411/feedback';
		const options = props[0];
		axios.post(url, qs.stringify(options))
			.then(() => {
				Store.call_modal = false;
				Store.success_message = true;
				setTimeout(() => {
					Store.success_message = false;
				}, 4000)
			})
	};

	@action orderCalculation = (...props) => {
		const url = 'contact-form-7/v1/contact-forms/1412/feedback';
		const options = props[0];
		axios.post(url, qs.stringify(options))
			.then(() => {
				Store.order_calculation_modal = false;
				Store.success_message = true;
				setTimeout(() => {
					Store.success_message = false;
				}, 4000)
			})
	};

}

const store = new Mail();
export default store;
