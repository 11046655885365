import React, {Component} from 'react';
import './MainSliderForm.scss';
import Input from "../Input";
import CustomCheckBox from "../CustomCheckBox";
import Button from "../Button";
import ErrorMessage from "../ErrorMessage";
import PhoneInput from "../PhoneInput";

class MainSliderForm extends Component {

	state = {
		checked: false,
		checkedError: false,
		data: {},
		error: {}
	};

	onHandleChange = (e) => {
		this.setState({...this.state, ...e}, () => console.log(this.state))
	};

	onHaldleSubmit = () => {
		if (this.state.checked) {
			console.log('send message')
		} else {
			this.setState({checkedError: true})
		}
	};

	render() {
		return (
			<div className="main_slider_form">
				<div className="main_slider_form_title">Оставить заявку и получить скидку на печать</div>
				<Input
					type="text"
					name="name"
					placeholder="Ваше имя"
					onCallBack={e => this.onHandleChange(e)}
					mbottom={10}
				/>
				<PhoneInput
					type="text"
					name="phone"
					placeholder="Укажите номер телефона"
					onCallBack={e => this.onHandleChange(e)}
					mbottom={10}
				/>
				<CustomCheckBox
					title="Cогласие"
					link="/privacy-policy"
					linkTitle="с политикой конфиденциальности"
					onCallBack={(e) => this.setState({...this.state, checked: e, checkedError: false})}
					checked={this.state.checked}
				/>
				{
					this.state.checkedError ? <ErrorMessage title="Вам нужно принять условия политики конфиденциальности"/> : null
				}
				<Button
					mtop={20}
					title="Отправить"
					onCallBack={this.onHaldleSubmit}
				/>
			</div>
		);
	}
}

export default MainSliderForm;
