import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './TextArea.scss';

class TextArea extends PureComponent {

	state = {
		value: '',
	};

	componentDidMount() {
		if(this.props.value) {
			this.setState({
				value: this.props.value
			})
		}
	}

	onHandleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		const obj = {
			[name]: value
		};
		this.setState({value}, () => this.props.onCallBack(obj))
	};

	render() {

		const style = {
			marginTop: this.props.mtop,
			marginBottom: this.props.mbottom,
		};

		return (
			<textarea
				style={style}
				className="smart_textarea"
				onChange={this.onHandleChange}
				placeholder={this.props.placeholder}
				name={this.props.name}
				value={this.state.value}
			/>
		);
	}
}

TextArea.propTypes = {
	placeholder: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	mtop: PropTypes.number,
	mbottom: PropTypes.number,
	onCallBack: PropTypes.func.isRequired,
};

export default TextArea;
