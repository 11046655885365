import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import {inject, observer} from "mobx-react";
import './DropProductNav.scss';
import Loading from "../../Elements/Loading";

@inject('Store')
@observer
class DropProductNav extends PureComponent {

	state = {
		active: false,
	};

	onHandleClick = (link) => {
		const {Store} = this.props;
		Store.product_link = link;
	};

	onHandleChange = () => {
		this.setState({active: !this.state.active})
	};

	render() {

		return (
			<li className="drop_product_item">
				<div
					className={this.state.active ? 'drop_product_title_active' : 'drop_product_title'}
					onClick={this.onHandleChange}>{this.props.array.title}
				</div>
				<div className={this.state.active ? 'drop_product_links_active' : 'drop_product_links'}>
					<ul>
						{
							this.props.array.child_items ? (
								this.props.array.child_items.map(item => {
									return (
										<li key={item.ID + '-product-' + item.title}>
											<NavLink onClick={() => this.onHandleClick(item.slug)}
											         to={this.props.slug + '/' + item.slug}>{item.title}</NavLink>
										</li>
									)
								})
							) : <Loading/>
						}
					</ul>
				</div>
			</li>
		);
	}
}

DropProductNav.propTypes = {
	array: PropTypes.object.isRequired,
};

export default DropProductNav;
