import React, {PureComponent} from 'react';
import './MainAboutText.scss';
import aboutPic from './main-about-pic.jpg';
import {Link} from "react-router-dom";
import AboutCounter from "./AboutCounter";

class MainAboutText extends PureComponent {
	render() {
		return (
			<div className="main_about_text">
				<div className="main_about_pic"><img src={aboutPic} alt="GMADV - сайт типографии"/></div>
				<div className="main_about_content">
					<h1>GMADV - сайт типографии</h1>
					<p>
						Типография «GMADV» занимает лидирующие позиции среди типографий в Москве. Мы распологаем современным
						оборудованием, которое справляется с любыми сложными задачами. Способны выполнить любые объемы при этом
						сохранив отличное качество.
					</p>
					<p>
						Наша компания производит следующую печатную продукцию: визитки, каталоги, открытки, календари, фирменные
						бланки, буклеты, листовки, флаеры, афиши, вывески, меню и многое другое. Мы также выполняем печать на
						футболках, кружках, ручках, флешках, бейсболках.
					</p>
					<p>
						Мы оказываем качественные услуги по: широкоформатной печати, цифровой печати, офсетной печати, шелкографии,
						резографии, широкоформатной УФ печати.
					</p>
					<p>
						За счет собственного производства мы можем оказывать услуги по доступным ценам. У нас всегда действуют
						выгодные акции и спецпредложения. Работаем как с физическими так и с юридическими лицами. Будем всегда рады
						видеть вас среди наших клиентов!
					</p>
					<Link to="/about">Подробнее о компании</Link>
				</div>
				<div className="about_counter_block">
					<AboutCounter count={350} title="Благодарных отзывов"/>
					<AboutCounter count={28} title="Заслуженных наград"/>
					<AboutCounter count={17} title="Лет надежной работы"/>
					<AboutCounter count={23} title="Филиалов по России"/>
					<AboutCounter count={75} title="Розничных магазинов"/>
				</div>
			</div>
		);
	}
}

export default MainAboutText;
