import {observable} from "mobx";

class Params {

	@observable data = {
		phone: '+7 (495) 128-99-89',
		linkPhone: 'tel:84951289989',
		email: 'zakaz@gmadv.ru',
		emailLink: 'mailto:zakaz@gmadv.ru',
		address: 'Пятницкое шоссе вл. 53',
		sendMessageText: 'Ваше сообщение отправленено, наш менеджер скоро с Вами свяжется! Спасибо за обращение.'
	};

}

const store = new Params();
export default store;
