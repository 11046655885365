import React, {PureComponent, Fragment} from 'react';
import './SuccessMessage.scss';
import {inject, observer} from "mobx-react";
import success from './pic/success.svg';
import close from './pic/close.svg';

@inject('Store', 'Params')
@observer
class SuccessMessage extends PureComponent {

	render() {

		const {Store, Params} = this.props;

		return (
			Store.success_message ? (
				<Fragment>
					<div className="success_message">
						<button onClick={() => Store.success_message = false}><img src={close} alt="Закрыть"/></button>
						<div className="success_message_icon"><img src={success} alt="Ваше сообщение отправлено"/></div>
						<div className="success_message_title">Ваше сообщение отправлено</div>
						<div className="success_message_message">{Params.data.sendMessageText}</div>
					</div>
					<div onClick={() => Store.success_message = false} className="success_message_overlay"/>
				</Fragment>
			) : null
		);
	}
}

SuccessMessage.propTypes = {};

export default SuccessMessage;
