import React, {PureComponent} from 'react';
import './PortfolioFilter.scss';
import PropTypes from 'prop-types';
import {inject, observer} from "mobx-react";
import Loading from "../Elements/Loading";

@inject('Store')
@observer
class PortfolioFilter extends PureComponent {
	render() {

		const {Store} = this.props;

		return (
			<div className="portfolio_filter">
				{
					Store.product_cat.length ? (
						<button
							onClick={() => this.props.onCallBack('all')}
							className={this.props.value === 'all' ? 'filter_active' : null}
						>Все портфолио</button>
					) : null
				}
				{
					Store.product_cat.length ? (
						Store.product_cat.map(item => {
							return item.slug === 'news' ? null : (
								<button
									key={item.id}
									className={this.props.value === item.slug ? 'filter_active' : null}
									onClick={() => this.props.onCallBack(item.slug)}>
									{item.name}
								</button>
							);
						})
					) : <Loading fullHeight={true}/>
				}
			</div>
		);
	}
}

PortfolioFilter.propTypes = {
	onCallBack: PropTypes.func.isRequired,
};

export default PortfolioFilter;
