import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import Menu from "./Menu";

@inject('Store', 'Actions')
@observer
class MainNav extends Component {

	componentDidMount() {
		const {Actions} = this.props;
		Actions.getNav();
	}

	render() {

		const {Store} = this.props;

		return (
			<nav className="main_nav">
				{
					Store.nav.map(item => {
						return (
							item.child_items ? (
								<Menu
									key={item.label + item.ID}
									drop={item.drop}
									title={item.title}
									link={item.slug ? item.slug : item.url}
									product={!item.slug}
									dropArray={item.child_items}
								/>
							) : (
								<Menu
									key={item.title + item.ID}
									title={item.title}
									link={item.slug}
								/>
							)
						)
					})
				}
			</nav>
		);
	}
}

export default MainNav;
