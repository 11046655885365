import {observable} from "mobx";

class Store {

	@observable link = window.location.origin;
	@observable call_modal = false;
	@observable order_calculation_modal = false;
	@observable slider_link = '';
	@observable product_link = '';
	@observable active_product_link = false;
	@observable nav = []; // Главная навигация
	@observable product_cat = []; // Основные рубрики
	@observable product_post_link = []; // Разделы в Услугах
	@observable router_links = []; // Ссылки для роутинга
	@observable products = []; // Вывод всех продуктов на странице /products
	@observable pages = []; // Все страницы
	@observable success_message = false; // Тригер "Сообщение отправленно"

	// Определенная страница
	@observable page = {
		status: false,
		data: {}
	};

	// Данные портфолио
	@observable portfolio = {
		status: false,
		data: {}
	};

	// Данные услуги (full product page)
	@observable product = {
		status: false,
		data: []
	};

	// Данные клиентов
	@observable clients = {
		status: false,
		data: []
	};

	// Данные преимуществ
	@observable advantages = {
		status: false,
		data: []
	};

	// Данные новостей
	@observable news = {
		status: false,
		data: []
	};

	@observable post = {
		status: false,
		data: [],
	}

}

const store = new Store();
export default store;
