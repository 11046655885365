import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

class ProductNavItem extends PureComponent {

	state = {
		active: false,
	};

	render() {

		return (
			<li className={this.state.active ? 'product_link_item_active' : 'product_link_item'}>
				<NavLink to={'/' + this.props.slug + '/' + this.props.link}>{this.props.title}</NavLink>
			</li>
		);
	}
}

ProductNavItem.propTypes = {
	link: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default ProductNavItem;
