import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Lightbox from "react-simple-lightbox";

class PortfolioItem extends PureComponent {
	render() {
		return (
			this.props.slug === 'all' ? (
				<Lightbox
					style={{
						maxHeight: "180px",
						overflow: "hidden",
					}}
				>
					<div className="portfolio_filter_item">
						<img src={this.props.pic} alt="Портфолио"/>
					</div>
				</Lightbox>
			) : (
				this.props.slug === this.props.razdel ? (
					<Lightbox
						style={{
							maxHeight: "180px",
							overflow: "hidden",
						}}
					>
						<div className="portfolio_filter_item">
							<img src={this.props.pic} alt="Портфолио"/>
						</div>
					</Lightbox>
				) : null
			)
		);
	}
}

PortfolioItem.propTypes = {
	pic: PropTypes.string.isRequired,
	razdel: PropTypes.string.isRequired,
};

export default PortfolioItem;
