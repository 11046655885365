import React, {Component} from 'react';
import './ErrorMessage.scss';

class ErrorMessage extends Component{
	render() {
		return (
			<p className="error_message">{this.props.title}</p>
		);
	}
}

export default ErrorMessage;
