import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './ProductGallery.scss';
import Lightbox from "react-simple-lightbox";

class ProductGallery extends PureComponent {
	render() {
		return (
			<div className="product_gallery">
				<div className="product_gallery_title">Фотогалерея</div>
				<div className="product_gallery_list">
					{
						this.props.array.map((item, index) => {
							return (
								<Lightbox
									key={index}
									style={{
										maxHeight: "180px",
										overflow: "hidden",
									}}
								>
									<div className="product_gallery_item">
										<img key={index} src={item} alt={this.props.title} />
									</div>
								</Lightbox>
							)
						})
					}
				</div>
			</div>
		);
	}
}

ProductGallery.propTypes = {
	title: PropTypes.string.isRequired,
	array: PropTypes.array.isRequired,
};

export default ProductGallery;
