import React, {Fragment, PureComponent} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import CenterBlock from "../components/Elements/CenterBlock/CenterBlock";
import ProductNav from "../components/ProductNav";
import {inject, observer} from "mobx-react";
import Pagination from "../components/Pagination";
import ProductCard from "../components/Elements/ProductCard";

@inject('Store', 'Actions')
@observer
class Product extends PureComponent {

	componentDidMount() {
		const {Store} = this.props;
		Store.active_product_link = true;
		this.checkLink();
	}

	checkLink = () => {
		const {Actions, Store} = this.props;
		if (this.props.slug === 'product') {
			Actions.getAllProductsNav();
			Actions.getAllProducts();
		} else {
			Actions.getSlugProducts(this.props.slug);
			Actions.getCategoryNav(this.props.slug)
		}
		Store.product_link = this.props.slug;
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.slug !== prevProps.slug){
			this.checkLink();
		}
	}

	componentWillUnmount() {
		const {Store} = this.props;
		Store.active_product_link = false;
		Store.products = [];
		Store.product_post_link = [];
	}

	render() {

		const {Store} = this.props;

		return (
			<Fragment>
				<Header main={false}/>
				<CenterBlock>
					<div className="content_position">
						<div className="content_left">
							<ProductNav slug={this.props.slug}/>
						</div>
						<div className="content_right">
							<Pagination
								title={this.props.title}
								slug={this.props.slug}
							/>
							<h1>{this.props.title}</h1>
							<div className="product_lists">
								{
									this.props.slug === 'product' ? (
										Store.products.map(item => {
											return (
												item.categories[0] === 38 ? null : (
													<ProductCard
														key={item.id}
														slug={this.props.slug}
														image={item.featured_image.large}
														title={item.title}
														price={item.acf.price}
														quantityTitle={item.acf.quantity}
														link={item.slug}
														category={item.categories[0]}
														product={true}
													/>
												)
											)
										})
										) : (
										Store.products.map(item => {
											return (
												<ProductCard
													key={item.id}
													slug={this.props.slug}
													image={item.featured_image.large}
													title={item.title}
													price={item.acf.price}
													quantityTitle={item.acf.quantity}
													link={item.slug}
													product={false}
												/>
											)
										})
									)

								}

							</div>
						</div>
					</div>
				</CenterBlock>
				<Footer/>
			</Fragment>
		);
	}
}

export default Product;
