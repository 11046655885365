import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class MainAdvantagesItem extends PureComponent {
	render() {
		return (
			<div className="main_advantages_item">
				<span>{this.props.title}</span>
				<span>{this.props.text}</span>
			</div>
		);
	}
}

MainAdvantagesItem.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default MainAdvantagesItem;
