import React, {Component} from 'react';
import Slider from "@farbenmeer/react-spring-slider";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";

@inject('Store')
@observer
class MainSlider extends Component {

	onSlideChange = (e) => {
		const {Store} = this.props;
		Store.slider_link = this.props.children[e].props.link;
	};

	render() {

		const style = {
			height: this.props.height,
			position: this.props.position,
			top: this.props.top,
			backgroundColor: this.props.background
		};

		const BulletComponent = ({onClick, isActive}) => (
			<li
				style={{
					width: '40px',
					height: '5px',
					backgroundColor: '#009F9C',
					margin: '0 5px',
					borderRadius: '5px',
					opacity: isActive && '0.5'
				}}
				onClick={onClick}
			/>
		);

		const {Store} = this.props;

		return (

			<div className="slider_block" style={style}>
				<Link to={Store.slider_link}/>
				<Slider
					hasBullets
					BulletComponent={BulletComponent}
					onSlideChange={this.onSlideChange}
				>
					{this.props.children}
				</Slider>
			</div>
		)
	}
}

export default MainSlider;
