import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Section.scss';

class Section extends Component{
	render() {

		const style = {
			marginTop: this.props.mtop,
			marginBottom: this.props.mbottom,
			paddingTop: this.props.ptop,
			paddingBottom: this.props.pbottom,
			backgroundColor: this.props.bg,
			padding: this.props.padding,
		};

		const section_title = {
			padding: this.props.sectionTitlePadding
		};

		const section_subtitle = {
			fontSize: '16px',
			lineHeight: '25px',
			textAlign: 'center',
			letterSpacing: '-0.05em',
			color: '#121212',
			marginTop: '10px',
		};

		return (
			<section
				id={this.props.id}
				style={style}
			>
				{this.props.title ? <div className="section_title" style={section_title}>{this.props.title}</div> : null}
				{this.props.subtitle ? <div className="section_subtitle" style={section_subtitle}>{this.props.subtitle}</div> : null }
				{this.props.children}
			</section>
		)
	}
}

export default Section;
Section.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	padding: PropTypes.string,
	sectionTitlePadding: PropTypes.string,
	mtop: PropTypes.number,
	mbottom: PropTypes.number,
	ptop: PropTypes.number,
	pbottom: PropTypes.number,
	bg: PropTypes.string,
};
