import React, {Component} from 'react';
import LogoIcon from './pic/logo.svg'
import FooterLogoIcon from './pic/footer-logo.svg'
import {Link} from "react-router-dom";

class Logo extends Component{
	render() {
		return (
			<div className="logo">
				<Link to='/'><img src={this.props.footer ? FooterLogoIcon : LogoIcon} alt="GMADV - Рекламное производство"/></Link>
			</div>
		);
	}
}

export default Logo;
