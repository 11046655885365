import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './MainBanner.scss';
import {Link} from "react-router-dom";

class MainBanner extends PureComponent {
	render() {

		const style = {
			width: '100%',
			height: this.props.height,
			backgroundImage: `url(${this.props.image})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center center',
		};

		return (
			<div className="main_banner" style={style}>
				<div className="main_banner_link">
					<Link to={this.props.link}>Описание и цены</Link>
				</div>
				<div className="main_banner_title">{this.props.title}</div>
				<div className="main_banner_overlay"/>
			</div>
		);
	}
}

MainBanner.propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	height: PropTypes.number.isRequired,
};

export default MainBanner;
