import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import './News.scss';

let dateFormat = require('dateformat');

class NewsItem extends PureComponent {
	render() {
		return (
			<div className="news_item">
				<Link to={`/news/${this.props.link}`}>
					<img src={this.props.pic} alt={this.props.title}/>
					<span className="news_item_date">{dateFormat(this.props.date, 'dd.mm.yyyy')}</span>
					<span className="news_item_title">{this.props.title}</span>
				</Link>
			</div>
		);
	}
}

NewsItem.propTypes = {
	link: PropTypes.string.isRequired,
	pic: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired
};

export default NewsItem;
