import React, {PureComponent} from 'react';
import ProductNavItem from "./ProductNavItem";
import './ProductNav.scss'
import {inject, observer} from "mobx-react";
import DropProductNav from "./DropProductNav";

@inject('Store')
@observer
class ProductNav extends PureComponent {

	render() {

		const {Store} = this.props;

		return (
			<div className="product_nav">
				<ul>
					{
						Store.product_link === 'product' ? (
							Store.product_post_link.map(item => {
								return <DropProductNav key={item.id + item.slug} slug={item.slug} array={item}/>
							})
						) : (
							Store.product_post_link.map(item => {
								return <ProductNavItem
									key={item.id + item.slug}
									link={item.slug}
									title={item.title}
									slug={this.props.slug}
								/>
							})
						)
					}
				</ul>
			</div>
		);
	}
}

export default ProductNav;
