import React, {PureComponent} from 'react';
import './Calculator.scss';
import Input from "../Forms/Input";
import PhoneInput from "../Forms/PhoneInput";
import CustomCheckBox from "../Forms/CustomCheckBox";
import ErrorMessage from "../Forms/ErrorMessage";
import Button from "../Forms/Button";
import blankPic from './blank-pic.jpg';

class Calculator extends PureComponent {

	state = {
		name: '',
		phone: '',
		email: '',
		width: '',
		height: '',
		checked: false,
		checkedError: false,
		total_size: 0,
		total_price: 0,
		maximum: false,
	};

	onHandleCalculate = (e) => {
		this.setState({...this.state, ...e}, () => this.checkTotal());
	};

	checkTotal = () => {
		const width = this.state.width;
		const height = this.state.height;
		const total_size = (parseInt(width) * parseInt(height)) / 1000;
		if (total_size <= parseInt(this.props.step1_area)) {
			const total = total_size * this.props.step1_price;
			this.setState({
				maximum: false,
				total_price: total.toFixed(2),
				total_size,
			})
		} else if (total_size < parseInt(this.props.step1_area + 0.1) && total_size <= parseInt(this.props.step2_area)) {
			const total = total_size * this.props.step2_price;
			this.setState({
				maximum: false,
				total_price: total.toFixed(2),
				total_size,
			})
		} else if (total_size < parseInt(this.props.step2_area + 0.1) && total_size <= parseInt(this.props.step3_area)) {
			const total = total_size * this.props.step2_price;
			this.setState({
				maximum: false,
				total_price: total.toFixed(2),
				total_size,
			})
		} else if (total_size >= parseInt(this.props.step_max) + 0.1) {
			const total = total_size * this.props.step2_price;
			this.setState({
				maximum: true,
				total_price: total.toFixed(2),
				total_size,
			});
		}
	};

	onHandleChange = (e) => {
		this.setState({...this.state, ...e}, () => console.log(this.state))
	};

	onHandleSubmit = () => {
		if (this.state.checked) {
			this.setState({checkedError: false});
			console.log('send message')
		} else {
			this.setState({checkedError: true})
		}
	};

	render() {

		const style = {
			backgroundImage: this.props.image ? 'url(' + this.props.image + ')' : 'url(' + blankPic + ')',
		};

		return (
			<div className="calculator_block">
				<div className="calculator_block_left">
					<div className="calculator_block_image" style={style}/>
					<div className="calculator">
						<div className="calculator_title">Оценка примерной стоимости</div>
						<div className="calculator_form">
							<div className="calculator_form_input">
								<Input
									type="number"
									name="width"
									placeholder=""
									value={this.state.width}
									onCallBack={e => this.onHandleCalculate(e)}
									mbottom={10}
									transparent={true}
								/>
								<span>Ширина, см</span>
							</div>
							<div className="calculator_form_input">
								<Input
									type="number"
									name="height"
									placeholder=""
									value={this.state.height}
									onCallBack={e => this.onHandleCalculate(e)}
									mbottom={10}
									transparent={true}
								/>
								<span>Длина, см</span>
							</div>
							<div className="calculator_total">
								<div className="calculator_total_size">
									Общая площадь:  <span>{isNaN(this.state.total_size) ? 0 : this.state.total_size}</span> м²
								</div>
								{
									this.state.maximum ? (
										<div className="calculator_total_price_error">
											Тиражи более <span>{this.props.step_max} {this.props.quantity}</span> обсуждаются индивидуально.
										</div>
									) : (
										<div className="calculator_total_price">Стоимость: {this.state.total_price} ₽</div>
									)
								}

							</div>
						</div>
					</div>
				</div>
				<div className="calculator_block_rigth">
					<div className="calc_form_title">Заказать {this.props.title}</div>
					<div className="calc_form">
						<Input
							type="text"
							name="name"
							placeholder="Ваше имя"
							onCallBack={e => this.onHandleChange(e)}
							mbottom={10}
						/>
						<Input
							type="email"
							name="email"
							placeholder="Ваш Email"
							value={this.state.email}
							mbottom={10}
							onCallBack={(e) => this.onHandleChange(e)}
						/>
						<PhoneInput
							type="text"
							name="phone"
							placeholder="Укажите номер телефона"
							onCallBack={e => this.onHandleChange(e)}
							mbottom={10}
						/>
						<CustomCheckBox
							title="Cогласие"
							link="/privacy-policy"
							mtop={20}
							linkTitle="с политикой конфиденциальности"
							checked={this.state.checked}
							onCallBack={(e) => this.setState({checked: e})}
						/>
						{
							this.state.checkedError ? <ErrorMessage title="Вам нужно принять условия политики конфиденциальности"/> : null
						}
						<Button
							width={270}
							mtop={20}
							title="Отправить"
							onCallBack={this.onHandleSubmit}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Calculator;
