import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Grid extends Component {
	render() {

		const style = {
			display: 'grid',
			gridTemplateColumns: `repeat(${this.props.count}, 1fr)`,
			gridGap: this.props.gap,
			marginTop: this.props.mtop,
			marginBottom: this.props.mbottom,
			paddingTop: this.props.ptop,
			paddingBottom: this.props.pbottom,
		};

		return (
			<div className="grid_block" id={this.props.id} style={style}>
				{this.props.children}
			</div>
		);
	}
}

export default Grid;
Grid.propTypes = {
	id: PropTypes.string,
	count: PropTypes.number.isRequired,
	gap: PropTypes.number.isRequired,
	mtop: PropTypes.number,
	mbottom: PropTypes.number,
	ptop: PropTypes.number,
	pbottom: PropTypes.number,
};
